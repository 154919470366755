import { useSelector } from "react-redux";
import { getTradePriceLabel } from "../utils/helpers/calculate-egc-dap";

const usePricing = () => {
    const {myBookings = {}, carDetails = {}, checkout = {}} = useSelector((state) => state) || {};

    const {
        paymentSummaryData: {
            tradeInOfferPrice = 0,
            tradeinSummary = {},
            amountDue = 0,
            dapSummary: {
                aggregatedItem: {
                    amount = 0
                } = {},
                lineItems = []
            } = {},
            summary: {
                aggregatedItem: {
                    currentPayableAmount = 0,
                    isPayableToUser = false
                } = {}
            } = {},
            totalDap: totalDapWithoutTradein = 0
        } = {} } = myBookings || {};

    const {
        content: {
            egc: egcPrice = 0,
            ewDetails: { egc: ewEgc = 0 } = {}
        } = {},
        isExtendedWarrantySelected
    } = carDetails || {};
    const {
        egcData = [],
        order: {
            chargeDetails = []
        },
        tradeInData: {
            offeredPrice =  0
        }} = checkout || {};

    const carPriceData = getTradePriceLabel({
        dapPrice: amount,
        currentPayableAmount,
        egcPrice: isExtendedWarrantySelected ? ewEgc : egcPrice,
        egcData,
        offeredPrice,
        tradeInOfferPrice,
        totalDapWithoutTradein,
        tradeinSummary,
        amountDue,
        isPayableToUser,
        chargeDetails,
        dapSummaryLineItems: lineItems
    });
    return {
        tradeinSummary,
        ...carPriceData
    };
};

export default usePricing;
