import React, {useState} from "react";
import styles from "./styles.css";
import LazyImage from "../../../../shared/lazy-image";
import CARS24Logo from "./images/cars24_logo.png";
import Tick from "./images/tick.png";
import Invalid from "./images/invalid.png";
import Cross from "./images/cross.png";
// import arrowImage from "./images/arrow.svg";
import LargeTick from "./images/large-tick.svg";
import PropTypes from "prop-types";
// import FreeRoadsideAssistanceSticker from "../../../free-roadside-assistance-sticker/component";
// import { trackMobileCustomEventsAU } from "../../../../../tracking";
// import { PLATINUM_COVER_GA_EVENTS } from "../../../cars24-patinum-cover/tracking";
import toggleArrow from "./images/accordion.svg";
import MarketIcon from "./images/market.svg";

const BENEFITS_CONFIG = {
    Pricing: {
        "heading": "Price/basic service",
        "cars24": "< $141+",
        "mechanics": "$220",
        "manufacturer": "$300+"
    },
    Value: {
        "heading": "Locked in price",
        "cars24": "Upto 3 years",
        "mechanics": "No",
        "manufacturer": "No"
    },
    Assurance: {
        "heading": "Tyre credit",
        "cars24": "$100 (on warranty extension)",
        "mechanics": "No",
        "manufacturer": "No"
    }
};

const SERVICE_BENEFITS_CONFIG = {
    Pricing: {
        "heading": "Parts covered",
        "cars24": "Manufacturer level",
        "manufacturer": "Limited items"
    },
    Value: {
        "heading": "Number of claims",
        "cars24": "Unlimited",
        "manufacturer": "Unlimited"
    },
    Assurance: {
        "heading": "Claim limit",
        "cars24": "Upto value of car",
        "manufacturer": "5000 per claim"
    },
    Finance: {
        "heading": "Claims management ",
        "cars24": "CARS24 staff",
        "manufacturer": "Third parties"
    },
    Support: {
        "heading": "Support",
        "cars24": "World class team at your service",
        "manufacturer": "Varies or limited support"
    }
};
const Cars24PlatinumCoverTheBestModal = ({isWarranty, isBundle, isWhatsCovered}) => {
    const [activeTab, setActiveTab] = useState(isWarranty ? 1 : 0);
    const [isOpen, setIsOpen] = useState(!isWhatsCovered);

    // useEffect(() => {
    //     if (isOpen) {
    //         trackMobileCustomEventsAU("", PLATINUM_COVER_GA_EVENTS.HOW_WE_ARE_BETTER_MODAL_OPENED);
    //     }
    // }, [isOpen]);

    return (
        <div styleName={"styles.wrapper"}>
            <div styleName={"styles.tabContainer"} onClick={() => setIsOpen(!isOpen)}>
                <p styleName={"styles.tabHeading"}><img src={MarketIcon} />Market comparison</p>
                <img styleName={isOpen ? "styles.toggleArrowRotates" : ""} src={toggleArrow} />
            </div>
            {isOpen && <React.Fragment>
                {isBundle && <div styleName={"styles.topSection"}>
                    <p styleName={"styles.title"}>Why is CARS24 platinum cover the best?</p>
                    <div styleName={"styles.tabWrapper"}>
                        <div styleName={`styles.tab ${activeTab === 0 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(0)}>
                            <p styleName={"styles.tabName"}>Servicing</p>
                        </div>
                        <div styleName={`styles.tab ${activeTab === 1 ? "styles.tabActive" : ""}`} onClick={() => setActiveTab(1)}>
                            <p styleName={"styles.tabName"}>Warranty</p>
                        </div>
                    </div>
                </div>}
                {!activeTab && <div styleName="styles.warrantyTable">
                    <table>
                        <tr>
                            <th >Feature</th>
                            <th>
                                <LazyImage src={CARS24Logo} />
                            </th>
                            <th>Manufacturer</th>
                            <th>Other mechanics</th>
                        </tr>
                        {Object.keys(BENEFITS_CONFIG).map(item => {
                            const {heading, cars24, mechanics, manufacturer} = BENEFITS_CONFIG[item];
                            return (
                                <tr>
                                    <td>{heading}</td>
                                    <td>
                                        <div>
                                            <img src={Tick} alt="available" height="16px" width="16px" />
                                            {cars24}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <img src={manufacturer === "No" ? Cross : Invalid} alt="invalid" height="16px" width="16px" />
                                            {manufacturer}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <img src={mechanics === "No" ? Cross : Invalid} alt="invalid" height="16px" width="16px" />
                                            {mechanics}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>}
                {!activeTab && <div styleName={"styles.serviceIncludeWrapper"}>
                    <p styleName={"styles.serviceInclude"}>Services include :</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Full vehicle inspection</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Test battery condition</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Top up engine oil</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Top up windscreen washer fluid</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Engine oil filter replacement</p>
                    <p styleName={"styles.list"}><img src={LargeTick} />Road test vehicle</p>
                </div>}

                {activeTab ? <React.Fragment> <div styleName="styles.serviceTableWrapper">
                    <table>
                        <tr>
                            <th >Feature</th>
                            <th>
                                <LazyImage src={CARS24Logo} />
                            </th>
                            <th>Avg. cover</th>
                        </tr>
                        {Object.keys(SERVICE_BENEFITS_CONFIG).map(item => {
                            const {heading, cars24, manufacturer} = SERVICE_BENEFITS_CONFIG[item];
                            return (
                                <tr>
                                    <td>{heading}</td>
                                    <td>
                                        <div>
                                            <img src={Tick} alt="available" height="16px" width="16px" />
                                            {cars24}
                                        </div>
                                    </td>
                                    <td>
                                        <div>
                                            <img src={Invalid} alt="invalid" height="16px" width="16px" />
                                            {manufacturer}
                                        </div>
                                    </td>
                                </tr>
                            );
                        })}
                    </table>
                </div>
                <p styleName={"styles.disclaimer"}>Based on typical aftermarket extended warranty products available on the Australian market.</p></React.Fragment> : <></>}
                {/* <FreeRoadsideAssistanceSticker /> */}
            </React.Fragment>}
        </div>
    );
};

Cars24PlatinumCoverTheBestModal.propTypes = {
    onClose: PropTypes.func,
    isOpen: PropTypes.bool,
    isWarranty: PropTypes.bool,
    isBundle: PropTypes.bool,
    isWhatsCovered: PropTypes.bool
};

export default Cars24PlatinumCoverTheBestModal;
