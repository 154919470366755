import React from "react";
import styles from "./styles.css";
import Skeleton from "./skeleton.js";
import Modal from "../../shared/modal";

export const EwSkipConfirmationLoader = () => {
    return (
        <Modal isOpen={true} overlayClass="testDriveOverlay">
            <div styleName={"styles.outer"}>
                <Skeleton/>
            </div>
        </Modal>
    );
};
