import React from "react";
import loadable from "@loadable/component";
import { connect } from "react-redux";
import RandomizedTextLoader from "../../shared/randomized-text-loader";
import { EXPERIMENT_TYPE } from "../../../constants/optimize-constants";
import { PropTypes } from "prop-types";
import LoaderScreen from "../loader-screen/component";

const mapStateToProps = ({ abExperiment: { msiteBi2ph } }) => ({
    msiteBi2ph
});

const BasicDetailsLoader = ({
    msiteBi2ph
}) => {
    const {data} = msiteBi2ph || {};
    const isBi2phVariant = data === EXPERIMENT_TYPE.VARIANT_B;
    return (<React.Fragment>
        {isBi2phVariant ? <RandomizedTextLoader height="66px" width="66px" fullScreen={true} /> :   <LoaderScreen heading="Please Wait" subHeading="Do not close the window or refresh, this might take a few seconds…" />}
    </React.Fragment>);
};

const BasicDetailsLoaderConnected = connect(mapStateToProps)(BasicDetailsLoader);

const CheckoutBasicDetails = loadable(() => import("./index"), {fallback: <BasicDetailsLoaderConnected />});

export default CheckoutBasicDetails;

BasicDetailsLoader.propTypes = {
    msiteBi2ph: PropTypes.object
};
