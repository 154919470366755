
export default (api) => {
    // eslint-disable-next-line no-unused-vars
    const getGeoLocationData = (headers) => {
        return api.get(`/geolocation`, {
            headers: {
                "cf-connecting-ip-custom": headers["cf-connecting-ip"] || "0.0.0.0"
            }},
        );
    };

    return {
        getGeoLocationData
    };
};
