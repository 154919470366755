/* eslint-disable complexity */
import qs from "query-string";
import { ORDER_STATUS } from "../../constants/checkout-constants";
import { EXPERIMENT_TYPE } from "../../constants/optimize-constants";
import isTestDriveEnabledState from "./is-test-drive-enabled-state";

export const getAbExpirementVariant = (key = {}, variant = "VARIANT_A") => (key && key.data === EXPERIMENT_TYPE[variant]) || false;

export const isExperimentNull = (value) => (!EXPERIMENT_TYPE.hasOwnProperty(value)) || false;

export const showTestDriveVariant = ({order = {}, content = {}, orderStatus = ""}) => {
    const {status = ""} = order || {};
    const {reserved, booked, displayTestDriveFlow} = content || {};
    let  showTdfViaQuery = false;
    if (typeof window !== "undefined") {
        const queryParams = qs.parse(window.location.search);
        showTdfViaQuery = (queryParams || {}).tdf === "true";//tdf => testdriveflow
    }
    const isEnabled = (showTdfViaQuery) &&
    (!booked) && !reserved &&
    (status || orderStatus) !== ORDER_STATUS.TEST_DRIVE_RESERVED &&
    (status || orderStatus) !== ORDER_STATUS.RESERVED &&
    (displayTestDriveFlow);
    return isEnabled;
};

export const showNewTestDriveVariant = ({
    order = {},
    content = {},
    orderStatus = "",
    userState,
    countryCode,
    isTestDriveEnabledForIndia,
    eligibleStateNames = []
}) => {
    const { status = "", testDriveStatus } = order || {};
    const { reserved, comingSoon, booked, state, listingActive } = content || {};
    const isTestDriveEnabledForIndiaFlag = countryCode === "IN" && isTestDriveEnabledForIndia;
    const isEnabled =
        !booked &&
        !comingSoon &&
        !reserved &&
        listingActive &&
        testDriveStatus !== ORDER_STATUS.TEST_DRIVE_COMPLETED &&
        isTestDriveEnabledState({state, userState, isTestDriveEnabledForIndiaFlag, eligibleStateNames}) &&
        (status || orderStatus) !== ORDER_STATUS.RESERVED;

    const isTdTestEnabled = typeof window !== "undefined" && window.localStorage.getItem("enableTestDrive") === "true";
    return isEnabled || isTdTestEnabled;
};

export const showPostWarrantyVariant = (showWarrantyPageShift) => {
    const {data: showWarrantyPageShiftData = "VARIANT_B"} = showWarrantyPageShift || {};
    const showPostWarrantyPage = showWarrantyPageShiftData !== EXPERIMENT_TYPE.VARIANT_B;
    return showPostWarrantyPage;
};

export const showCdpBiCtaVariant = () => {
    const variantDetails = {
        text: "Get Started",
        variant: (EXPERIMENT_TYPE.VARIANT_A).replace("_", " ").toLowerCase()
    };
    return variantDetails;
};

export const getFinanceDiscoveryVariant = ({ queryParams: params = {}, preApproved = false, showFinanceCentricListing = false}) => {
    const { fc = false } = params || {};
    const financeDiscoveryVariant = "VARIANT_B";

    const isFinanceDiscoveryVariant =  !preApproved;
    const isFinanceDiscoveryLayout = fc || showFinanceCentricListing;
    return {isFinanceDiscoveryVariant, isFinanceDiscoveryLayout, financeDiscoveryVariant};
};

export const checkActiveExperimentVariants = (key = {}, experimentTypes = []) => {
    return experimentTypes.some(variant => getAbExpirementVariant(key, variant));
};
