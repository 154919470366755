import React from "react";
import styles from "./skeleton.css";

const Skeleton = () => {
    return (
        <React.Fragment>
            <div styleName={"styles.grid"}>
                <div className="shimmer" styleName={"styles.header"} />
                <div className="shimmer" styleName={"styles.specifications"} />
                <div className="shimmer" styleName={"styles.specifications"} />
                <div className="shimmer" styleName={"styles.remove"} />
            </div>
        </React.Fragment>
    );
};

export default Skeleton;
