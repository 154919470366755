import React, {useState} from "react";

import { PropTypes } from "prop-types";
import loadable from "@loadable/component";
import { EW_REMOVAL_SCREENS } from "./constants";
import { EwPitchingSkipLoader } from "../ew-pitching-skip-modal/loader";
import { EwSkipConfirmationLoader } from "../ew-pitching-skip-confirmation/loader";

const EWPitchingSkipModal = loadable(() => import("../ew-pitching-skip-modal"), { fallback: <EwPitchingSkipLoader/> });
const EWPitchingSkipConfirmation = loadable(() => import("../ew-pitching-skip-confirmation"),  { fallback: <EwSkipConfirmationLoader/> });

const STEP_COMPONENTS = [
    {
        step: EW_REMOVAL_SCREENS.WARRANTY_REMOVAL,
        component: EWPitchingSkipModal
    },
    {
        step: EW_REMOVAL_SCREENS.WARRANTY_REMOVAL_CONFIRMATION,
        component: EWPitchingSkipConfirmation
    }
];

const EwChargesRemovalFlow = ({
    isOpen = false,
    onClose,
    removeCB = () => {}
}) => {
    const [currentStep, setCurrentStep] = useState(STEP_COMPONENTS[0]);

    const handleNextClick = (val) => {
        const nextStep = val.length
            ? STEP_COMPONENTS.find(item => item.step === val)
            : STEP_COMPONENTS.find((_, index) => index === STEP_COMPONENTS.findIndex(item => item.step === currentStep.step) + 1);
        if (nextStep) setCurrentStep(nextStep);
    };

    const handleClose = () => {
        onClose();
        setCurrentStep(STEP_COMPONENTS[0]);
    };

    const Component = currentStep.component;

    return (
        <React.Fragment>
            {currentStep.component && (
                <Component
                    onClose={handleClose}
                    isOpen={isOpen}
                    handleNextStep={handleNextClick}
                    removeCB={removeCB}
                />
            )}
        </React.Fragment>
    );
};

EwChargesRemovalFlow.propTypes = {
    isOpen: PropTypes.bool,
    onClose: PropTypes.func,
    removeCB: PropTypes.func
};

export default EwChargesRemovalFlow;
