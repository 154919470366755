/* eslint-disable max-statements */
/* eslint-disable complexity */
import React, { useState } from "react";
import PropTypes from "prop-types";
import UploadedDocumentCard from "../uploaded-document-card";
import UploadFlieCard from "../upload-flie-card";
import FilePreviewModal from "../file-preview-modal";

const VerifyLicenceUploadFileContainer = ({
    fileSize,
    fileExtensionsRegex,
    fileExtenionText,
    onUploadFile = () => { },
    uploadDrivingLicenceConnect = () => { },
    ...restProps
}) => {
    const [filePreviewData, setFilePreviewData] = useState({ display: false });
    const { field = {}, props = {}, form = {}, disabled = false } = restProps || {};
    const { type, mutiUpload, maxLength, isFormikFeildArray } = props || {};
    const { value, name } = field || {};
    const [isLoading, setIsloading] = useState(false);

    const uploadFormData = async (file) => {
        const { size, name: fileName } = file;
        const formDataValue = new window.FormData();
        formDataValue.append("file", file);
        formDataValue.set("tag", type || null);
        onUploadFile(file, field.name, type);
        setIsloading(true);
        try {
            const resp = await uploadDrivingLicenceConnect(formDataValue, type.toUpperCase());
            let formValue = [{ size, fileName, filePath: resp.filePath }];
            if ((value || {}).length) {
                formValue = [...value, ...{ size, fileName, filePath: resp.filePath }];
            }
            if (isFormikFeildArray) {
                form.setFieldValue(name, { size, fileName, filePath: resp.filePath });
            } else {
                form.setFieldValue(name, formValue);
            }
            setIsloading(false);
        } catch (e) {
            setIsloading(false);
        }
    };

    const onRemoveDocument = (index) => {
        if (isFormikFeildArray) {
            form.setFieldValue(name, null);
        } else if ((value || {}).length) {
            let splicedValue = value;
            if (index > -1) {
                splicedValue.splice(index, 1);
            }
            splicedValue = value.length ? splicedValue : null;
            form.setFieldValue(name, splicedValue);
        }
    };

    const handleFilePreview = async ({
        fileName,
        payload
    }) => {
        try {
            setFilePreviewData({
                display: true,
                fileName,
                fileSrc: payload.filePath
            });
        } catch (e) {
            // console.log("==error==",error)
        }
    };

    const onCloseImagePreview = () => {
        setFilePreviewData({
            display: false
        });
    };

    const showUploadCard = () => {
        if (isLoading) {
            return false;
        } else if (mutiUpload && (value || []).length < maxLength) {
            return true;
        } else if (!value) {
            return true;
        }
        return false;
    };

    const renderUploadedDocumentCard = (file, key) => {
        return (
            <UploadedDocumentCard {...file} hideFileConversion={file?.isDefaultFileSize} key={key} index={key} onClickFileCallback={handleFilePreview} onRemoveDocument={onRemoveDocument} disableRemove={disabled}/>
        );
    };

    return (
        <React.Fragment>
            {!!(filePreviewData.display) &&
                <FilePreviewModal {...filePreviewData} onClickBack={onCloseImagePreview} />
            }
            {/* {Array.isArray(value) && value.map((file, key) => renderUploadedDocumentCard(file, key))} */}
            {value && isFormikFeildArray && (Object.keys(value) || {}).length && renderUploadedDocumentCard(value, 0)}
            {isLoading && <UploadedDocumentCard isLoading={true} {...value} />}
            {showUploadCard() && <UploadFlieCard onUpload={uploadFormData}
                fileSize={fileSize}
                fileExtensionsRegex={fileExtensionsRegex}
                fileExtenionText={fileExtenionText}
                {...restProps}
            />}
        </React.Fragment>
    );
};

VerifyLicenceUploadFileContainer.propTypes = {
    fileSize: PropTypes.string,
    restProps: PropTypes.object,
    fileExtensionsRegex: PropTypes.string,
    financeUploadDocumentConnect: PropTypes.func,
    getPreSignedFinanceUploadedImageConnect: PropTypes.func,
    onUploadFile: PropTypes.func,
    fileExtenionText: PropTypes.string,
    uploadDrivingLicenceConnect: PropTypes.func
};

export default VerifyLicenceUploadFileContainer;
