import statsigEnv from "./statsig-host";

export const EXPERIMENT_NAME = {
    ab_test: "ab_test",
    c2bSellLandingMobileNumber: "c2b_sell_landing_mobile_number",
    financeFirstCheckoutRevamp: "finance_first_checkout_revamp",
    showClpLoanCalculator: "msite_clp_loan_calculator",
    web_mobile_pre_bi_assistance: "web_mobile_pre_bi_assistance",
    servicing_bundle_revamp: "servicing_bundle_revamp",
    web_location_highlighting: `${statsigEnv()}_web_location_highlighting`,
    web_price_drop_msite: "web_price_drop_msite",
    web_price_drop_desktop: "web_price_drop_desktop",
    event_banners: `${statsigEnv()}_event_banners`,
    hubspot_assistance_desktop: `${statsigEnv()}_desktop_hubspot_assistance`,
    msite_refurb_visibility_cdp: `${statsigEnv()}_msite_refurb_visibility_cdp`,
    msite_bi2ph: `${statsigEnv()}_msite_bi2ph`,
    gallery_on_the_fly_desktop: `${statsigEnv()}_gallery_on_the_fly_desktop`,
    interstate_pitch_v2: `${statsigEnv()}_interstate_pitch_v2`,
    msite_cdp_ew: `${statsigEnv()}_msite_cdp_ew`,
    msite_product_pitching: `${statsigEnv()}_msite_product_pitching`
    // config_poc: `${statsigEnv()}_config_poc`
};

export const AB_TEST_VARIATIONS = {
    CONTROL: "CONTROL",
    VARIANT: "VARIANT",
    VARIANT_A: "VARIANT_A",
    VARIANT_B: "VARIANT_B"
};

export const SERVICING_VARIANT_MAPPING = {
    CONTROL: "OLD",
    VARIANT: "TESTING"
};

export const WEB_VIEW_EXPERIMENT_TO_REDUCER_MAPPING = {
    bi2Phone: "msiteBi2ph"
};
