
export const VERIFY_LICENCE_STATES = {
    UPLOAD_PENDING: "UPLOAD_PENDING",
    UPLOAD_COMPLETED: "UPLOAD_COMPLETED",
    VERIFICATION_PENDING: "VERIFICATION_PENDING",
    ID_VERIFIED: "ID_VERIFIED",
    FAILED: "FAILED"
};

export const LICENCE_FILE_UPLOADED_STATES = [
    VERIFY_LICENCE_STATES.UPLOAD_COMPLETED,
    VERIFY_LICENCE_STATES.VERIFICATION_PENDING,
    VERIFY_LICENCE_STATES.ID_VERIFIED,
    VERIFY_LICENCE_STATES.FAILED
];
