/* eslint-disable no-nested-ternary */
/* eslint-disable max-statements */
import React from "react";
import styles from "./styles.css";
import Unselected from "./images/unselected.svg";
import Selected from "./images/selected.svg";
import BestValue from "./images/badge.png";
import PropTypes from "prop-types";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";

// eslint-disable-next-line complexity
const PlatinumCoverMonthlyCard = ({
    type,
    coverDetails,
    isActive,
    setSelectedProductIndex,
    index,
    clearSelection,
    showPriceWithEmi,
    bottomSticker,
    hideClearButton,
    repaymentsPerWeek,
    isPostBcCard
}) => {
    const { title, description, tag, products, name, discountedPrice } = coverDetails || {};
    const isBundleSection = products?.length > 0;
    const isBestValue = tag?.toLowerCase() === "best value";

    return (
        <div
            styleName={`styles.outer ${isActive ? "styles.cardActive" : ""}`}
            onClick={() => {
                setSelectedProductIndex(index);
            }}
        >
            {isBestValue && (
                <div styleName={"styles.bestValue"}>
                    <img src={BestValue} alt="Best value sticker" />
                </div>
            )}
            <div styleName={"styles.wrapper"}>
                <div className="media" styleName={"styles.cardContainer"}>
                    <img src={isActive ? Selected : Unselected} alt="Unselected Checkbox" />
                    <div className="media-body">
                        <p styleName={"styles.heading"}>{title || name}</p>
                        <div styleName={"styles.amountWrapper"}>
                            <p styleName={"styles.amountPerWeek"}>
                                {type === "loan" ? (
                                    <React.Fragment>{makePriceLabelRound(repaymentsPerWeek)}/wk</React.Fragment>
                                ) : (
                                    makePriceLabelRound(discountedPrice)
                                )}
                            </p>
                            {(showPriceWithEmi || (type === "loan" && isPostBcCard)) && (
                                <p styleName={"styles.listPrice"}>{makePriceLabelRound(discountedPrice)}</p>
                            )}
                        </div>
                        {!isBundleSection && Array.isArray(description) ? (
                            description?.length > 1 ? (
                                <ul styleName={"styles.list"}>
                                    {description.map((item) => (
                                        <li>{item.value}</li>
                                    ))}
                                </ul>
                            ) : (
                                <p styleName={"styles.singleDescription"}>{description[0].value}</p>
                            )
                        ) : null}

                        {isBundleSection && (
                            <ul>
                                {isBundleSection &&
                                    products.map((product, listIndex) => (
                                        <li key={listIndex} styleName={"styles.list"}>
                                            {product.bundleDescription}
                                        </li>
                                    ))}
                            </ul>
                        )}

                        {!isPostBcCard && !isBundleSection && (
                            <p styleName={"styles.singleDescription"}>{description}</p>
                        )}
                    </div>
                </div>
            </div>
            {bottomSticker && (
                <div styleName={"styles.bottomSticker"}>
                    <p styleName={"styles.savedText"}>{bottomSticker}</p>
                </div>
            )}

            {isActive && !hideClearButton && (
                <p
                    styleName={"styles.clearSelect"}
                    onClick={(e) => clearSelection(e, index)}
                >
                    Remove
                </p>
            )}
        </div>
    );
};

PlatinumCoverMonthlyCard.propTypes = {
    type: PropTypes.string,
    coverDetails: PropTypes.object,
    isActive: PropTypes.bool,
    setSelectedProductIndex: PropTypes.func,
    index: PropTypes.number,
    clearSelection: PropTypes.func,
    showPriceWithEmi: PropTypes.bool,
    bottomSticker: PropTypes.string,
    hideClearButton: PropTypes.bool,
    repaymentsPerWeek: PropTypes.number,
    isPostBcCard: PropTypes.bool
};

export default PlatinumCoverMonthlyCard;
