/* eslint-disable complexity */
/* eslint-disable max-statements */
import React, { useState, useRef, useEffect } from "react";
import { NUMBER } from "../../../constants/app-constants";
import PropTypes from "prop-types";
import styles from "./styles.css";
import { useHistory } from "react-router-dom";

const FormLayoutV1 = ({
    formConfig,
    setFormLayoutProgress = () => { },
    updateFormData = () => { },
    handleOnNext = () => { },
    isNextLoading,
    enableGoToNext = true,
    preApprovalEditMode = false,
    children,
    handleOnBack,
    leftComponent,
    topComponent,
    rightColStyles = "",
    outerContainer = "",
    topComponentProps,
    leftComponentProps,
    rightComponent,
    middleComponent,
    middleComponentProps,
    rightComponentProps,
    steps,
    ...restProps
}) => {
    const { middleComponentStyles, formContainerStyles } = middleComponentProps || {};
    const { rightComponentStyles } = rightComponentProps || {};

    const history = useHistory();
    const formRef = useRef();
    const [errorOnSubmitCount, setFormErrorCount] = useState(0);
    const { disableResumeJourney } = formConfig || {};
    const [stageNo, setStageNo] = useState((disableResumeJourney || !restProps.goToStageNo) ? NUMBER.ONE : restProps.goToStageNo);
    const [pageNo, setPageNo] = useState((disableResumeJourney || !restProps.goToPageNo) ? NUMBER.ONE : restProps.goToPageNo);
    const stages = formConfig?.stages?.filter(Boolean) || [];
    const currentStage = stages[stageNo - 1] || {};
    const currentStageForms = currentStage?.forms?.filter(Boolean) || [];
    const currentFormData = currentStageForms[pageNo - 1] || {};
    const totalPages = currentStageForms.length;
    const totalStages = (stages || []).length;

    const goToRoute = (newStageNo) => {
        const newStage = stages[newStageNo - 1] || {};
        if (newStage.route) {
            history.push(newStage.route);
        }
    };

    useEffect(() => {
        goToRoute(1);
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onStepClick = (goToStepNo) => {
        setPageNo(1);
        setStageNo(goToStepNo);
        goToRoute(goToStepNo);
    };

    const handlPillEdit = (step) => {
        const { setCompletedStages = () => { } } = topComponentProps || {};
        let { completedStages = [] } = topComponentProps || {};
        completedStages = completedStages || [];

        if (completedStages.indexOf(step) !== -1) {
            const updatedStages = completedStages.slice(0, completedStages.indexOf(step));
            setCompletedStages(updatedStages);
        }
    };

    const onClickBack = (e) => {
        e.preventDefault();
        const jumpToPage = currentFormData.jumpToPrevPageNo && currentFormData.jumpToPrevPageNo();
        const jumpToStage = currentStage.jumpToPrevStageNo && currentStage.jumpToPrevStageNo();
        /* if not first page */
        if (pageNo !== 1) {
            setPageNo(jumpToPage || pageNo - 1);
        } else if (stageNo !== 1) {   /* if not first stage */
            const updatedStageNo = stageNo - 1;
            goToRoute(jumpToStage || updatedStageNo);
            setStageNo(jumpToStage || updatedStageNo);
            /* go to last page logic for prev stage*/
            const stageData = stages[(jumpToStage || updatedStageNo) - 1] || [];
            const stageDataTotalPages = stageData.forms.filter(Boolean).length;
            setPageNo(stageDataTotalPages);

            if (preApprovalEditMode) handlPillEdit(stageNo);
        }
        const isFirstPage = pageNo === 1 && stageNo === 1;
        handleOnBack(isFirstPage);
        if (currentFormData.onClickBack) {
            currentFormData.onClickBack();
        }
    };
    const onClickNext = () => {
        setFormErrorCount(errorOnSubmitCount + 1);
    };

    useEffect(() => {
        setFormLayoutProgress({
            stageNo,
            pageNo
        });
    }, [pageNo, setFormLayoutProgress, stageNo]);

    useEffect(() => {
        if (pageNo) {
            formRef.current.scrollTo(0, 0);
        }
    }, [pageNo]);

    const goToNext = () => {
        const jumpToPage = currentFormData.jumpToNextPageNo && currentFormData.jumpToNextPageNo();
        const jumpToStage = currentStage.jumpToNextStageNo && currentStage.jumpToNextStageNo();
        /* if not last page */
        if (pageNo < totalPages) {
            setPageNo(jumpToPage || pageNo + 1);
        } else if (stageNo < totalStages) { /* if not last stage */
            goToRoute(stageNo + 1);
            setPageNo(NUMBER.ONE);
            setStageNo(jumpToStage || stageNo + 1);
        }
        setFormErrorCount(0);
        if (currentFormData.onClickNext) {
            currentFormData.onClickNext();
        }
    };

    const onClickSubmit = async (values) => {
        const isLastPage = pageNo === totalPages && totalStages === stageNo;
        const pageStagesNum = {
            pageNo,
            totalPages,
            totalStages,
            stageNo
        };
        handleOnNext({
            values,
            isLastPage,
            currentStage,
            pageStagesNum
        }).then(() => {
            if (enableGoToNext) goToNext();
        }).catch(() => {
            //err
        });
    };

    const LeftComponent = leftComponent;
    const TopComponent = topComponent;
    const RightComponent = rightComponent;
    const MiddleComponent = middleComponent;

    const handlePillClick = (goToStepNo) => {
        const { onPillClickCustom = () => { } } = topComponentProps || {};
        if (preApprovalEditMode)  handlPillEdit(goToStepNo);

        setPageNo(1);
        setStageNo(goToStepNo);
        goToRoute(goToStepNo);

        onPillClickCustom();
    };

    return (
        <React.Fragment>
            {topComponent &&
                <TopComponent
                    onPillClick={handlePillClick}
                    steps={stages}
                    currentStep={stageNo}
                    completedPercentage={restProps.completedPercentage}
                    maxCompletedSteps={restProps.goToStageNo}
                    isError={errorOnSubmitCount && !isNextLoading}
                    color={restProps.color}
                    {...topComponentProps}
                />
            }
            <div styleName={"styles.flexWrapper"} className={`${outerContainer ? outerContainer : ""}`}>
                {leftComponent && <div styleName={"styles.leftCol"}>
                    <LeftComponent
                        onStepClick={onStepClick}
                        steps={steps}
                        currentStep={stageNo}
                        maxCompletedSteps={restProps.goToStageNo}
                        {...leftComponentProps}
                    />
                </div>}
                <div styleName={"styles.rightCol"} className={`${rightColStyles ? rightColStyles : ""}`}>
                    <div styleName={"styles.wrapper"} className={`${middleComponentStyles ? middleComponentStyles : ""}`}>
                        <div styleName={"styles.formContainer"} className={`${formContainerStyles ? formContainerStyles : ""}`} ref={formRef}>
                            <MiddleComponent
                                onClickSubmit={onClickSubmit}
                                updateFormData={updateFormData}
                                currentFormData={currentFormData}
                                errorOnSubmitCount={errorOnSubmitCount}
                                children={children}
                                onClickBack={onClickBack}
                                onClickNext={onClickNext}
                                isNextLoading={isNextLoading}
                                currentStage={currentStage}
                                {...middleComponentProps}
                                {...restProps}
                            >
                                {children}
                            </MiddleComponent>
                        </div>
                    </div>
                    {rightComponent && <div styleName={"styles.rightOuter"} className={`${rightComponentStyles ? rightComponentStyles : ""}`}>
                        <RightComponent
                            completedPercent={restProps.completedPercentage}
                            {...rightComponentProps}
                        />
                    </div>}
                </div>
            </div>
        </React.Fragment>
    );
};

FormLayoutV1.propTypes = {
    formConfig: PropTypes.object,
    handleOnNext: PropTypes.func,
    handleOnBack: PropTypes.func,
    children: PropTypes.func,
    setFormLayoutProgress: PropTypes.func,
    completedPercentage: PropTypes.number,
    leftComponentProps: PropTypes.object,
    topComponentProps: PropTypes.object,
    rightComponentProps: PropTypes.object,
    middleComponentProps: PropTypes.object,
    isNextLoading: PropTypes.bool,
    preApprovalEditMode: PropTypes.bool,
    enableGoToNext: PropTypes.bool,
    updateFormData: PropTypes.func,
    leftComponent: PropTypes.func,
    topComponent: PropTypes.func,
    rightComponent: PropTypes.func,
    screenType: PropTypes.string,
    rightColStyles: PropTypes.string,
    outerContainer: PropTypes.string,
    steps: PropTypes.array,
    middleComponent: PropTypes.func,
    formContainerStyles: PropTypes.string
};

export default FormLayoutV1;
