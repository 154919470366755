import React from "react";
import styles from "./styles.css";
import Skeleton from "./skeleton";
import Modal from "../../shared/modal";

export const EwPitchingSkipLoader = () => {
    return (
        <Modal isOpen={true} overlayClass="testDriveOverlay">
            <div styleName="styles.outer">
                <Skeleton />
            </div>
        </Modal>
    );
};
