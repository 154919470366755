import React from "react";
import styles from "./styles.css";
import Loader from "./images/loader.gif";
import PropTypes from "prop-types";

const LoaderWithText = ({loaderText = "", height = "40px", width = "40px", fullScreen = false, showText = true}) => {
    return (
        <div styleName={`styles.loaderContainer ${fullScreen ? "styles.fullScreenHeightLoader" : ""}`}>
            <div styleName={"styles.loaderWrap"}>
                <img src={Loader} height={height} width={width} />
                {(loaderText && showText) && <p styleName="styles.loaderText">{loaderText}</p>}
            </div>
        </div>
    );
};

export default LoaderWithText;

LoaderWithText.propTypes = {
    loaderText: PropTypes.string,
    height: PropTypes.string,
    width: PropTypes.string,
    fullScreen: PropTypes.bool,
    showText: PropTypes.bool
};
