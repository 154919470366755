import React from "react";
import styles from "./styles.css";
import PropTypes from "prop-types";

const LoaderScreen = ({
    icon,
    subHeading = false,
    heading = "Loading...",
    title = "Please wait.."
}) => {
    return (
        <div className="container" styleName={"styles.loaderWrapper"}>
            {icon && <img src={icon} alt="Car Icon" />}
            <div styleName={"styles.spinner styles.primary"} role="status">
                {heading && <span styleName={"styles.loading"}>{heading}</span>}
            </div>
            {title && <p styleName={"styles.wait"}>{title}</p>}
            {subHeading && <p styleName={"styles.detail"}>{subHeading}</p>}
        </div>
    );
};

LoaderScreen.propTypes = {
    subHeading: PropTypes.string,
    heading: PropTypes.string,
    title: PropTypes.string,
    icon: PropTypes.string
};

export default LoaderScreen;
