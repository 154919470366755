import React, { useEffect, useState } from "react";
import { EXPERIMENT_TYPE } from "../../../../constants/optimize-constants";
import BookingsFooter from "../../bookings-footer";
import styles from "./styles.css";
import PropTypes from "prop-types";

import CheckIcon from "./images/check-icon.svg";
import { getMyBookingCarDetailsURL } from "../../../../utils/helpers/get-detail-page-url";
import { postPaymentRoutes } from "../../../../constants/au.desktop/post-payment-routes";
import { SIGN_CONTRACT_STATUS, VERIFY_LICENCE_STATES } from "../../../../constants/checkout-constants";
import VerifyDrivingLicence from "../../verify-driving-licence";
import { VERIFY_LICENCE_SCREEN_TYPES } from "./constants";
import StepsComponentRevamp from "../../../shared/steps-component-revamp";
import Button from "../../../shared/button";
import { trackDesktopCustomEventsAU } from "../../../../tracking";
import { AU_DESKTOP_EVENTS } from "../../../../tracking/au.desktop-events";
import DynamicFormLoader from "../../dynamic-form-loader";
import { NUMBER } from "../../../../constants/app-constants";
import LoaderScreen from "../../loader-screen/component";
import useCustomHistoryOperations from "../../../../hooks/use-custom-history-operation";
import { getAllSettledPromises } from "../../../../utils/helpers/get-all-settled-promises";

const STEPS = [
    "You’ll be required to upload a valid Australian driving license for verification.",
    "We will cross-check your details with official Australian ID databases.",
    "Additional identity documents may be requested to ensure full verification.",
    "Only the driving license of the intended car owner should be uploaded for this process."
];

const VerifyUserLicencey = ({ order, signContractData = [], getPaymentSummaryConnect, drivingLicenceData, setLicenceUploadScreenTypeConnect, getLicenceUploadStatusByOrderIdConnect }) => {
    const { screenType = VERIFY_LICENCE_SCREEN_TYPES.INTRO }  = drivingLicenceData || {};
    const { customReplace } = useCustomHistoryOperations();
    const { documentStatus } = Array.isArray(signContractData) && signContractData[0] || {};
    const [dataLoading, setDataLoading] = useState(false);

    const { orderId = "", drivingLicenceStatus: orderDlStatus } = order;

    const handleSkip = async () => {
        const { relativeURL } = getMyBookingCarDetailsURL(order.appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
        await customReplace(relativeURL);
    };

    const handleVerifyIdClick = () => {
        trackDesktopCustomEventsAU(AU_DESKTOP_EVENTS.GREEN_ID_BUTTON_CLICK, { eventLabel: `${orderId}` });
        setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM);
    };

    useEffect(() => {
        if (screenType === VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION) {
            window.setTimeout(() => {
                const { relativeURL } = getMyBookingCarDetailsURL(order.appointmentId, postPaymentRoutes.signContract.route, null, EXPERIMENT_TYPE.VARIANT_B);
                customReplace(relativeURL);
            }, NUMBER.THREE_THOUSAND);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [screenType]);

    const renderScreen = () => {
        switch (screenType) {
        case VERIFY_LICENCE_SCREEN_TYPES.INTRO:
            return (
                <div>
                    <p styleName={"styles.header"}>Verify your ID</p>
                    <div styleName="styles.reserveCarInfo">
                        <img src={CheckIcon} alt="info" />
                        <div styleName="styles.subHeader">
                            <p styleName={"styles.congratulation"}>Congratulations on reserving your car!</p>
                            <p styleName={"styles.congratulationSubHeading"}>Order isn’t complete until you verify your ID and sign the contract of sale</p>
                        </div>
                    </div>
                    <div styleName="styles.stepsWrapper">
                        <StepsComponentRevamp title="Next Steps" steps={STEPS} />
                    </div>
                    <div styleName={"styles.skipDls"}>
                        {
                            documentStatus !== SIGN_CONTRACT_STATUS.SIGNED &&
                            <a onClick={handleSkip} styleName={"styles.skipDLText"}>Skip, I don’t have Australian DL</a>
                        }
                        <Button text={"Verify licence"} onClick={handleVerifyIdClick} />
                    </div>
                </div>
            );
        case VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM:
            return <VerifyDrivingLicence drivingLicenceData={drivingLicenceData}/>;
        default:
            return null;
        }
    };

    const fetchOrderAndDeliveryDetails = async () => {
        setDataLoading(true);

        let asyncOperations = { paymentSummary: getPaymentSummaryConnect(orderId) };

        if (orderDlStatus !== VERIFY_LICENCE_STATES.UPLOAD_PENDING) {
            asyncOperations = {
                ...asyncOperations,
                dlData: getLicenceUploadStatusByOrderIdConnect()
            };
        }

        const { dlData } = await getAllSettledPromises(asyncOperations);
        if (dlData && (dlData?.value?.status !== VERIFY_LICENCE_STATES.UPLOAD_PENDING)) {
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_FORM);
        } else {
            setLicenceUploadScreenTypeConnect(VERIFY_LICENCE_SCREEN_TYPES.INTRO);
        }

        setDataLoading(false);
    };

    useEffect(() => {
        if (orderId) {
            fetchOrderAndDeliveryDetails();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orderId]);

    return screenType === VERIFY_LICENCE_SCREEN_TYPES.UPLOAD_CONFIRMATION ? (
        <DynamicFormLoader title="Documents uploaded successfully" isCashBooking={true} />
    ) : (
        <React.Fragment>
            {dataLoading ? (
                <LoaderScreen title={"Please wait"} />
            ) : (
                <div className="row">
                    <div className="col-lg-7" styleName="styles.wrapper">
                        {renderScreen()}
                    </div>
                    <div className="col-lg-5">
                        <div styleName={"styles.getInTouchWrapper"}>
                            <BookingsFooter
                                heading=""
                                subHeading="It takes us some time to confirm your payment with our bank. Your updated payment will reflect here after confirmation, post which you can proceed with paying remaining amount (if pending). Meanwhile please sign your contract and upload your drivers licence."
                            />
                        </div>
                    </div>
                </div>
            )}
        </React.Fragment>
    );
};

VerifyUserLicencey.propTypes = {
    order: PropTypes.object,
    signContractData: PropTypes.array,
    getPaymentSummaryConnect: PropTypes.func,
    drivingLicenceData: PropTypes.object,
    setLicenceUploadScreenTypeConnect: PropTypes.func,
    getLicenceUploadStatusByOrderIdConnect: PropTypes.func
};

export default VerifyUserLicencey;
