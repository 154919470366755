/* eslint-disable max-statements */
// eslint-disable-next-line complexity
import React, {memo, useState} from "react";
import PropTypes from "prop-types";
import {connect} from "react-redux";

import styles from "./styles.css";
import Modal from "../../shared/modal";
import CloseButton from "../../shared/icon-cross";
import H2Regular from "../../shared/h2-regular/component";
import { makePriceLabelRound } from "../../../utils/helpers/make-price-label";
import ContactOurTeamCard from "../contact-our-team-card";
import { PAYMENT_SUMMARY_ACTION } from "../../../constants/checkout-constants";
import EwChargesRemovalFlow from "../ew-charges-removal-flow";
import RegistrationChargesFlow from "../registration-charges-flow";
import { trackMobileCustomEventsAU } from "../../../tracking";
import { AU_MOBILE_EVENTS } from "../../../tracking/au.mobile-events";

const SUMMARY_TYPES = {
    ACCORDION: "ACCORDION",
    MODAL: "MODAL"
};

const getItemType = (item) => {
    const summary = Object.keys(item?.summary || {}).length > 0 ? item.summary : {};
    if (summary?.viewType === SUMMARY_TYPES.MODAL && (item?.action === PAYMENT_SUMMARY_ACTION.EDIT_REG_MODAL)) {
        return SUMMARY_TYPES.MODAL;
    } else if (item?.action === PAYMENT_SUMMARY_ACTION.REMOVE_CDP_WARRANTY) {
        return SUMMARY_TYPES.MODAL;
    } else if (summary?.viewType === SUMMARY_TYPES.ACCORDION) {
        return SUMMARY_TYPES.MODAL;
    } else {return null;}
};
const DriveAwayPriceModal = memo(({
    onClose,
    paymentSummaryData,
    showCachedData,
    cachedPaymentSummaryData,
    deliveryMode,
    hideChatNudge = true,
    isFinanceFirstCheckout,
    onDeliveryStateChange,
    orderDeliveryMode
}) => {
    const { dapSummary = {} } =  showCachedData ? cachedPaymentSummaryData[deliveryMode || orderDeliveryMode] || {} : paymentSummaryData || {};
    const [showModalFlows, setShowModalFlows] = useState([]);

    const renderSubItems = (summary) => {
        return (
            <div styleName={"styles.secondSectionWrap"}>

                {summary?.lineItems?.map((subItem) => (
                    <div styleName={"styles.firstSectionWrapper"}>
                        <p styleName={"styles.leftText"}>{subItem?.label}</p>
                        <p styleName={"styles.priceInBold"}>{subItem?.type === "SUBTRACTION" ? `-${makePriceLabelRound(subItem?.amount)}` : makePriceLabelRound(subItem?.amount)}</p>
                    </div>
                ))}
            </div>
        );
    };

    const itemClicked = (item) => {
        if (getItemType(item) === SUMMARY_TYPES.MODAL && item.action === PAYMENT_SUMMARY_ACTION.EDIT_REG_MODAL) {
            setShowModalFlows([PAYMENT_SUMMARY_ACTION.EDIT_REG_MODAL]);
        } else if (item.action === PAYMENT_SUMMARY_ACTION.REMOVE_CDP_WARRANTY) {
            setShowModalFlows([PAYMENT_SUMMARY_ACTION.REMOVE_CDP_WARRANTY]);
            trackMobileCustomEventsAU(AU_MOBILE_EVENTS.EW_POPUP_OPEN, {
                eventAction: "source",
                eventLabel: "checkout_modal"
            });
        }
    };

    return (
        <Modal isOpen={true} close={onClose}>
            <div styleName={"styles.car_cover_main"}>
                <div styleName={"styles.heading"}>
                    <H2Regular text="Drive Away Price" />
                    <div styleName={"styles.closeWrapper"}>
                        <CloseButton type="grey" onClickHandler={onClose} />
                    </div>
                </div>
                <div styleName={"styles.contentWrap"}>
                    <div styleName={"styles.priceBreakupWrapper"}>
                        {dapSummary?.lineItems?.map((item) => (
                            <React.Fragment key={item?.key}>
                                <div styleName={"styles.firstSectionWrapper"}
                                    onClick={() => itemClicked(item)}
                                >
                                    <p styleName={getItemType(item) === SUMMARY_TYPES.MODAL ? "styles.drivePrice" : "styles.leftText"}>{item?.label}</p>
                                    <p styleName={"styles.priceInBold"}>
                                        {item?.type === "ADDITION"
                                            ? makePriceLabelRound(item?.amount)
                                            : `-${makePriceLabelRound(item?.amount)}`}
                                    </p>
                                </div>
                                {item?.disclaimers?.length > 0 && (item.disclaimers || []).map((disclaimer, index) => (
                                    <p key={index} styleName={"styles.disclaimersItemText"}>{disclaimer}</p>
                                ))}
                                {getItemType(item) === SUMMARY_TYPES.ACCORDION && renderSubItems(item?.summary)}
                            </React.Fragment>
                        ))}

                        <div styleName={"styles.firstSectionWrapper styles.border"}>
                            <p styleName={"styles.leftTextTotalPrice"}>{dapSummary?.aggregatedItem?.label}</p>
                            <p styleName={"styles.totalPrice"}>
                                {makePriceLabelRound(Math.abs(dapSummary?.aggregatedItem?.amount))}
                            </p>
                        </div>
                    </div>

                    <div styleName={"styles.bottomTncContainer"}>

                        {Object.keys(dapSummary?.termsAndConditions || {}).length > 0 && (
                            <React.Fragment>
                                {(dapSummary?.termsAndConditions || []).map((tnc, index) => (
                                    <div styleName={"styles.bottomBox"} key={index}>
                                        <p styleName={"styles.bottomText"} key={index}>
                                            {tnc}
                                        </p>
                                    </div>
                                ))}
                            </React.Fragment>
                        )}
                    </div>

                    {!hideChatNudge && <div styleName={"styles.contactTeam"}>
                        <ContactOurTeamCard />
                    </div>}
                </div>
                <RegistrationChargesFlow isOpen={showModalFlows.includes(PAYMENT_SUMMARY_ACTION.EDIT_REG_MODAL)} onClose={() =>  setShowModalFlows([])} isFinanceFirstCheckout={isFinanceFirstCheckout} onDeliveryStateChange={onDeliveryStateChange}/>
                <EwChargesRemovalFlow isOpen={showModalFlows.includes(PAYMENT_SUMMARY_ACTION.REMOVE_CDP_WARRANTY)} onClose={() => setShowModalFlows([])}/>
            </div>
        </Modal>
    );
});

const mapStateToProps = ({
    checkout: {
        deliveryMode,
        order: {
            deliveryMode: orderDeliveryMode
        }
    },
    myBookings: {
        paymentSummaryData,
        cachedPaymentSummaryData
    }
}) => ({
    deliveryMode,
    paymentSummaryData,
    cachedPaymentSummaryData,
    orderDeliveryMode
});

DriveAwayPriceModal.propTypes = {
    onClose: PropTypes.func,
    paymentSummaryData: PropTypes.object,
    deliveryMode: PropTypes.string,
    cachedPaymentSummaryData: PropTypes.object,
    showCachedData: PropTypes.bool,
    hideChatNudge: PropTypes.bool,
    isFinanceFirstCheckout: PropTypes.bool,
    onDeliveryStateChange: PropTypes.func,
    orderDeliveryMode: PropTypes.string
};
export default connect(mapStateToProps, null)(DriveAwayPriceModal);
