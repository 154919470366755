const PROMISE_STATUS = {
    FULFILLED: "fulfilled",
    REJECTED: "rejected",
    PENDING: "pending"
};
export const getAllSettledPromises = async (asyncOperations) => {
    const results = await Promise.allSettled(Object.values(asyncOperations));
    return results.reduce((acc, result, index) => {
        const key = Object.keys(asyncOperations)[index];
        acc[key] = result.status === PROMISE_STATUS.FULFILLED ? result.value : result.reason;
        return acc;
    }, {});
};
