import React from "react";
import styles from "./styles.css";
import pdfIcon from "./images/pdf-icon.svg";
import imgIcon from "./images/image-icon.svg";
import CloseButton from "../../shared/icon-cross";
import PropTypes from "prop-types";
import { getConvertedFileSize } from "../../../utils/helpers/get-conveted-filesize";
import ArrowIcon from "../../shared/arrow";

const UploadedDocumentCard = ({
    isLoading,
    fileName,
    filePath,
    size,
    index,
    imageSource,
    onRemoveDocument = () => {},
    onClickFileCallback = () => {},
    disableRemove = false,
    hideFileConversion = false
}) => {
    const regex = /\.pdf$/i;
    const isPdf = regex.test(fileName);
    const imgSrc = isPdf ? pdfIcon : imgIcon;
    const payload = {
        filePath
    };
    const onClickFile = () => {
        onClickFileCallback({
            fileName,
            payload
        });
    };
    const onClickClose = (e) => {
        e.stopPropagation();
        onRemoveDocument(index);
    };
    return (
        <React.Fragment>
            {isLoading ?
                <div styleName={"styles.payslipOuter"} >
                    <p>uploading...</p>
                </div> :
                <div styleName={"styles.payslipOuter"}>
                    <div styleName={"styles.close"}>
                        {
                            !disableRemove && <CloseButton type="grey" onClickHandler={onClickClose}/>
                        }
                    </div>
                    <img src={isPdf ? pdfIcon : (imageSource || imgSrc)} styleName={imageSource ? "styles.imageSource" : ""} />
                    <div>
                        <h5 onClick={onClickFile}>{fileName} <ArrowIcon blue={true}/></h5>
                        <p>{hideFileConversion ? size : getConvertedFileSize(size)}</p>
                    </div>
                </div>}
        </React.Fragment>
    );
};

UploadedDocumentCard.propTypes = {
    isLoading: PropTypes.bool,
    fileName: PropTypes.string,
    filePath: PropTypes.string,
    size: PropTypes.number,
    onRemoveDocument: PropTypes.func,
    index: PropTypes.number,
    onClickFileCallback: PropTypes.func,
    imageSource: PropTypes.string,
    disableRemove: PropTypes.bool,
    hideFileConversion: PropTypes.bool
};
export default UploadedDocumentCard;
